<template>
  <div
    class="help-wrapper"
    id="popular-queries"
  >
    <div class="help-container">
      <div
        class="help-container__title"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
      >
        Master every task with ease
      </div>
      <div class="help-container__content">
        <div
          v-for="item in items"
          :key="item.title"
          class="help-item"
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="1000"
        >
          <div
            class="flex flex-col gap-[4px] xxl:flex-row xxl:gap-[8px] xxl:items-end"
          >
            <img
              :src="item.iconPath"
              :alt="item.title"
              class="w-[40px] h-[40px]"
            />
            <div class="help-item__title">
              {{ item.title }}
            </div>
          </div>
          <ul class="help-item__info">
            <li
              v-for="tip in item.tips"
              class="help-item__info-tip"
            >
              <span>
                {{ tip }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="help-container__footer"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="1000"
      >
        Millions of Other Topics …
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: 'Easy Learning',
          iconPath: '/assets/images/landing/get-help/learning.webp',
          tips: [
            'Simplify inertia for a young student',
            'Explain supply and demand with real estate',
            'Define "oxidation" with a practical example',
            'Describe how mitochondria produce energy',
          ],
        },
        {
          title: 'Self-Growth',
          iconPath: '/assets/images/landing/get-help/self-growth.webp',
          tips: [
            'Recommend 5 top books on entrepreneurship',
            'Tips to build self-confidence in social settings',
            'Stress management tools for busy individuals',
            'How to maintain a positive mindset in tough times',
          ],
        },
        {
          title: 'Work',
          iconPath: '/assets/images/landing/get-help/work.webp',
          tips: [
            'Write a CV for a Junior Software Developer',
            'Create promotional texts for a new pet shop',
            'Tips to excel in a tech job interview',
            'Top digital marketing strategies for businesses',
          ],
        },
        {
          title: 'Travelling',
          iconPath: '/assets/images/landing/get-help/traveling.webp',
          tips: [
            'Top 10 places to visit in Nice, France',
            'Best beginner-friendly hiking trails in the Swiss Alps',
            'Suggest a scenic road trip route in North America',
            'Best local dishes to try in Bangkok',
          ],
        },
        {
          title: 'Health & Well-Being',
          iconPath: '/assets/images/landing/get-help/health.webp',
          tips: [
            'Daily vitamin C intake for optimal health',
            'Simple exercises to relieve lower back pain',
            "Tips for getting a restful night's sleep",
            'Benefits of regular yoga practice',
          ],
        },
        {
          title: 'Entertainment',
          iconPath: '/assets/images/landing/get-help/entertainment.webp',
          tips: [
            'Create a new superhero with unique powers',
            'Recommend a feel-good road trip playlist',
            'List 5 must-watch horror movies',
            'Write a short story with an unexpected twist',
          ],
        },
        {
          title: 'Tips & How-Tos',
          iconPath: '/assets/images/landing/get-help/tips.webp',
          tips: [
            'How to organize my workspace for productivity',
            'Tips for effective time management',
            'Strategies to improve concentration while studying',
            'Strategies to improve concentration while studying',
          ],
        },
        {
          title: 'Daily Inspiration',
          iconPath: '/assets/images/landing/get-help/inspiration.webp',
          tips: [
            '5 quotes to inspire life changes',
            'A daily ritual to attract love and positivity',
            'How to start and maintain a dream journal',
            'Simple acts of kindness to brighten someone\'s day',
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.help-wrapper {
    @apply w-full bg-[white];
}

.help-container {
    @apply px-[20px] py-[40px] bg-[white] w-[375px] mt-0 m-auto;
    @screen lg {
        @apply mx-auto py-[80px] w-[1280px];
    }
}

.help-container__title {
    @apply font-sans text-[32px] font-bold leading-[38px] tracking-[0px] text-left w-[270px];
    @screen lg {
        @apply text-[54px] w-full;
    }
}

.help-container__content {
    @apply mt-[24px] grid grid-cols-[repeat(2,1fr)] gap-x-[19px] gap-y-[25px];
    @screen lg {
        @apply mt-[40px] grid-cols-[repeat(4,1fr)] gap-x-[16px] gap-y-[32px];
    }
}

.help-item {
    @screen lg {
        &:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2;
        }

        &:nth-child(2) {
            grid-area: 2 / 1 / 3 / 2;
        }

        &:nth-child(3) {
            grid-area: 1 / 2 / 2 / 3;
        }

        &:nth-child(4) {
            grid-area: 2 / 2 / 3 / 3;
        }

        &:nth-child(5) {
            grid-area: 1 / 3 / 2 / 4;
        }

        &:nth-child(6) {
            grid-area: 2 / 3 / 3 / 4;
        }

        &:nth-child(7) {
            grid-area: 1 / 4 / 2 / 5;
        }

        &:nth-child(8) {
            grid-area: 2 / 4 / 3 / 5;
        }
    }
}

.help-item__title {
    @apply font-sans text-[16px] font-bold leading-[30px] tracking-[0px] text-left;
}

.help-item__info {
    @apply mt-[6px] ml-[16px];
}

.help-item__info-tip {
    @apply font-sans mt-[8px] text-[14px] font-normal leading-[16px] tracking-[0px] text-left opacity-[80%] list-disc w-[150px];

    &::marker {
        @apply text-[#0FAB6D] text-sm;
    }

    span {
        @apply relative -left-[6px];
    }

    @screen lg {
        @apply w-[300px] leading-[18px];
    }
}

.help-container__footer {
    @apply font-sans mt-[16px] ml-[10px] opacity-[80%] text-[14px] font-normal leading-[18px] tracking-[0px] text-left;
    @screen lg {
        @apply mt-[8px];
    }
}
</style>
